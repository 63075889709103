
html {
  margin: 0 !important;
  padding: 0 !important;
  height: 100vh;
  scroll-behavior: smooth;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  min-height: 100vh;
  background-color: #96230F !important;
  /*overflow-x: hidden !important;*/
}

#root {
  margin: 0 !important;
  /*padding: 0 !important;*/
  min-height: 100vh;
  /*padding-top:8px;*/ /* white strip bottom fix */
}

img {
  max-width: 100% !important;
}
